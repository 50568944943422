import React from "react";
import { Dialog, DialogContent, DialogHeader } from "~/src/primitives/dialog";
import { ALL_TIMEZONES } from "../../../util/timezones";
import { CalendarClock } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "~/src/primitives/command";

interface EditTimezoneModalProps {
  show: boolean;
  previous: string;
  onChange: (updated: string) => void;
  onHide: () => void;
}

const TZ_SELECT_OPTIONS = ALL_TIMEZONES.sort((a, b) => a.localeCompare(b));

const EditTimezoneModal = ({
  show,
  onChange,
  onHide,
}: EditTimezoneModalProps) => {
  return (
    <Dialog open={show} onOpenChange={(open) => !open && onHide()}>
      <DialogContent>
        <DialogHeader className="flex flex-row items-center justify-center">
          <CalendarClock size={20} className="mr-3" />
          <div className="m-0">Edit Timezone</div>
        </DialogHeader>
        <Command className="rounded-lg border shadow-md">
          <CommandInput
            className={
              "border-transparent focus:border-transparent focus:ring-0"
            }
            placeholder="Search Timezones"
          />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {TZ_SELECT_OPTIONS.map((e, i) => (
                <CommandItem key={`tz-${i}`} className="cursor-pointer">
                  <div
                    className="h-full w-full"
                    onClick={() => {
                      onChange(e);
                      onHide();
                    }}
                  >
                    {e}
                  </div>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </DialogContent>
    </Dialog>
  );
};

export default EditTimezoneModal;
