import React, { useState, useRef } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "~/src/primitives/dialog";

import { B64, fileToB64 } from "../../util/loaders";
import "./select-image-modal.css";
import { ImageIcon, Upload } from "lucide-react";
import { cn } from "~/src/util/reusables";
import { Button } from "~/src/primitives/button";

interface SelectImageModalProps {
  show: boolean;
  onChange: (image: B64) => void;
  onHide: () => void;
}

const SelectImageModal = ({
  show,
  onChange,
  onHide,
}: SelectImageModalProps) => {
  const [image, setImage] = useState<B64>();
  const fileInputRef = useRef<HTMLInputElement>();

  const handleUpload = async (e: any) => {
    const file = e.target.files[0];
    if (!file) return;

    const b64: B64 = await fileToB64(file);
    setImage(b64);
  };

  return (
    <Dialog open={show} onOpenChange={(open) => !open && onHide()}>
      <DialogContent>
        <DialogHeader className="flex flex-row items-center justify-center">
          <ImageIcon size={20} className="mr-3" />
          <div className="m-0">Select Image</div>
        </DialogHeader>
        <div>
          <div className={cn("flex", "items-center", "justify-evenly")}>
            {image?.b64 && (
              <img
                crossOrigin="anonymous"
                src={image.b64 as string}
                className={cn("w-[100px]", "rounded-sm")}
              />
            )}
            <Button
              variant="outline"
              onClick={() => fileInputRef?.current?.click()}
            >
              <Upload className="mr-4 h-4 w-4" />
              Choose From Files
            </Button>
          </div>
          <br />
          <input
            type="file"
            accept="image/*"
            className={cn("fl-input", "file-upload", "hidden")}
            ref={fileInputRef}
            onChange={handleUpload}
            data-testid="image-upload"
          />
        </div>

        <DialogFooter>
          <button
            className={cn("fl-button", "pill", "small", !image && "secondary")}
            disabled={!image}
            onClick={() => {
              if (image) {
                onChange(image);
                setImage(undefined);
                onHide();
              }
            }}
            data-testid="save-pfp"
          >
            Continue
          </button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SelectImageModal;
