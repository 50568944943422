import { User } from "lucide-react";
import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "~/src/primitives/dialog";

interface EditBusinessNameModalProps {
  show: boolean;
  previous: string;
  onChange: (updated: string) => void;
  onHide: () => void;
}

const EditBusinessNameModal = ({
  show,
  previous,
  onChange,
  onHide,
}: EditBusinessNameModalProps) => {
  const [updated, setUpdated] = useState<string>();

  return (
    <Dialog open={show} onOpenChange={(open) => !open && onHide()}>
      <DialogContent>
        <DialogHeader className="flex flex-row items-center justify-center">
          <User size={20} className="mr-3" />
          <div className="m-0">Change Business Name</div>
        </DialogHeader>
        <input
          type="text"
          className="fl-input"
          data-testid="firstname-input"
          placeholder={previous ?? "Business Name"}
          onChange={(e) => setUpdated(e.target.value)}
        />
        <DialogFooter>
          <button
            className="fl-button pill small"
            data-testid="property-button"
            onClick={() => {
              onChange(updated);
              onHide();
            }}
          >
            Save
          </button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default EditBusinessNameModal;
