import { TextCursorInput } from "lucide-react";
import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "~/src/primitives/dialog";

interface EditPropertyModalProps {
  show: boolean;
  name: string;
  previous: string;
  onChange: (updated: string) => void;
  onHide: () => void;
}

const EditPropertyModal = ({
  show,
  name,
  previous,
  onChange,
  onHide,
}: EditPropertyModalProps) => {
  const [updated, setUpdated] = useState<string>();

  return (
    <Dialog open={show} onOpenChange={(open) => !open && onHide()}>
      <DialogContent>
        <DialogHeader className="flex flex-row items-center justify-center">
          <TextCursorInput size={20} className="mr-3" />
          <div className="m-0">Edit {name}</div>
        </DialogHeader>
        <div>
          <input
            type="text"
            className="fl-input"
            data-testid="property-input"
            placeholder={previous}
            onChange={(e) => setUpdated(e.target.value)}
          />
        </div>

        <DialogFooter>
          <button
            className="fl-button pill"
            data-testid="property-button"
            onClick={() => {
              onChange(updated);
              onHide();
            }}
          >
            Save
          </button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default EditPropertyModal;
