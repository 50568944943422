import React, { ReactNode, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
} from "~/src/primitives/dialog";
import { Alert, AlertTitle, AlertDescription } from "~/src/primitives/alert";
import { Button } from "~/src/primitives/button";
import { Input } from "~/src/primitives/input";

interface ConfirmModalProps {
  show: boolean;
  header?: string;
  alertIcon: JSX.Element;
  alertTitle: string;
  alertDescription: string;
  confirmationKey?: string;
  onConfirm: () => void | Promise<void>;
  onCancel?: () => void;
  onHide: () => void;
  action?: string;
  children?: ReactNode;
  isDestructive?: boolean;
  cancel?: string;
}

const ConfirmModal = ({
  show,
  header,
  alertIcon,
  alertTitle,
  alertDescription,
  confirmationKey,
  action,
  isDestructive,
  cancel,
  children,
  onConfirm,
  onCancel,
  onHide,
}: ConfirmModalProps) => {
  const [confirmation, setConfirmation] = useState<string>();

  const keyCheck = () => !confirmationKey || confirmation === confirmationKey;

  const handleConfirm = async () => {
    keyCheck() && (await onConfirm());
  };

  return (
    <Dialog
      open={show}
      onOpenChange={(open) => {
        open || onHide();
      }}
    >
      <DialogContent>
        <DialogHeader className="text-center text-sm">
          <strong>{header ?? "Action Required"}</strong>
        </DialogHeader>
        <div>
          <div data-testid="confirm-modal"></div>
          <Alert className="mb-3 flex flex-row justify-start space-x-3 px-1">
            <div>{alertIcon}</div>
            <div>
              <AlertTitle className="mb-3">{alertTitle}</AlertTitle>
              <AlertDescription>{alertDescription}</AlertDescription>
            </div>
          </Alert>
          {confirmationKey && (
            <Input
              type="text"
              spellCheck="false"
              placeholder={confirmationKey}
              data-testid="confirm-input"
              onChange={(e) => setConfirmation(e.target.value)}
            />
          )}
        </div>
        {children}
        <DialogFooter>
          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              onHide?.();
              onCancel?.();
            }}
          >
            {cancel ?? "Cancel"}
          </Button>
          <Button
            variant={isDestructive ? "destructive" : "default"}
            size="sm"
            onClick={async () => await handleConfirm()}
            disabled={!keyCheck()}
          >
            {action ?? "Confirm"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmModal;
