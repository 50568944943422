import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "~/src/primitives/dialog";

import { B64 } from "../../util/loaders";
import { loadImage, cropByPercent } from "../../util/conversion";
import ReactCrop, { Crop, PercentCrop } from "react-image-crop";
import { Crop as CropIcon } from "lucide-react";
import BarLoader from "react-spinners/BarLoader";
import "./crop.style.css";
import { cn } from "~/src/util/reusables";

interface CropModalProps {
  show: boolean;
  load: () => Promise<B64>;
  onCrop: (blob: Blob) => void;
  onHide: () => void;
}

const CropModal = ({ show, load, onCrop, onHide }: CropModalProps) => {
  const [rawAsset, setRawAsset] = useState<B64>();
  const [crop, setCrop] = useState<Crop>();
  const [percentCrop, setPercentCrop] = useState<PercentCrop>();
  const [pending, setPending] = useState<boolean>(false);

  useEffect(() => {
    // Get dimensions from raw asset to set up initial crop
    (async () => {
      if (load) {
        const raw = await load();

        if (!raw) return;

        const image = await loadImage(raw?.b64 as string);

        const crop: PercentCrop = {
          unit: "%",
          width:
            image.width < image.height
              ? 100
              : 100 * (image.height / image.width),
          height:
            image.height < image.width
              ? 100
              : 100 * (image.width / image.height),
          x: 0,
          y: 0,
        };

        setCrop(crop);
        setPercentCrop(crop);
        setRawAsset(raw);
      }
    })();
  }, [load]);

  return (
    <Dialog open={show} onOpenChange={(open) => !open && onHide()}>
      <DialogContent>
        <DialogHeader className="flex flex-row items-center justify-center">
          <CropIcon size={20} className="mr-3" />
          <div className="m-0">Crop</div>
        </DialogHeader>

        {pending ? (
          <div
            className={cn("flex", "items-center", "justify-center")}
            style={{ padding: 15 }}
          >
            <BarLoader loading={pending} />
          </div>
        ) : (
          rawAsset && (
            <ReactCrop
              crop={crop}
              aspect={1}
              maxHeight={400}
              maxWidth={400}
              onChange={(c) => {
                setCrop(c);
              }}
              onComplete={(c, pc) => {
                setPercentCrop(pc);
              }}
              className={cn("rounded-sm", "max-h-400")}
            >
              <img
                crossOrigin="anonymous"
                src={rawAsset.b64 as string}
                className="upload-image"
              />
            </ReactCrop>
          )
        )}
        <DialogFooter>
          <button
            className="fl-button pill"
            data-testid="crop-button"
            onClick={async () => {
              if (percentCrop) {
                setPending(true);
                const cropped = await cropByPercent(rawAsset, percentCrop);
                onCrop(cropped);
                onHide();
              }
            }}
          >
            Crop
          </button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CropModal;
