import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "~/src/primitives/dialog";
import { Alert, AlertDescription, AlertTitle } from "~/src/primitives/alert";
import "../account.style.css";
import { AlertCircleIcon, Lock } from "lucide-react";

interface ResetPasswordModalProps {
  show: boolean;
  onHide: () => void;
  onReset: (password: string) => void;
}

const ResetPasswordModal = ({
  show,
  onHide,
  onReset,
}: ResetPasswordModalProps) => {
  const [password, setPassword] = useState<string>("");
  const [verify, setVerify] = useState<string>("");
  const [error, setError] = useState<string>();

  const handleVerifyPassword = () => {
    if (password !== verify) {
      return setError("Passwords do not match");
    }

    if (password.length < 8) {
      return setError("Your password must be at least 8 characters.");
    }

    clearStateAndHide();
    onReset(password);
  };

  const clearStateAndHide = () => {
    setPassword("");
    setVerify("");
    setError(undefined);
    onHide();
  };

  return (
    <Dialog open={show} onOpenChange={(open) => !open && onHide()}>
      <DialogContent onOpenAutoFocus={(e) => e.preventDefault()}>
        <DialogHeader className="flex flex-row items-center justify-center">
          <Lock size={20} className="mr-3" />
          <div className="m-0">Reset Your Password</div>
        </DialogHeader>
        <div>
          {error ? (
            <>
              <Alert variant="destructive">
                <AlertTitle className="flex flex-row items-center space-x-3">
                  <div>
                    <AlertCircleIcon size={20} />
                  </div>
                  <div>Error</div>
                </AlertTitle>
                <AlertDescription>{error}</AlertDescription>
              </Alert>
              <br />
            </>
          ) : (
            <></>
          )}
          <label className="fl-label">New password:</label>
          <br />
          <input
            type="password"
            data-testid="password-input"
            className="fl-input"
            onChange={(e) => setPassword(e.target.value)}
          />
          <br />
          <label className="fl-label">Confirm New password:</label>
          <br />
          <input
            type="password"
            data-testid="verify-input"
            className="fl-input"
            onChange={(e) => setVerify(e.target.value)}
          />
          <br />
          <br />
        </div>
        <DialogFooter>
          <button
            className="fl-button pill small"
            onClick={handleVerifyPassword}
            data-testid="reset-confirm-button"
          >
            Reset Password
          </button>{" "}
          <button
            className="fl-button secondary pill small"
            onClick={clearStateAndHide}
            data-testid="close-button"
          >
            Close
          </button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ResetPasswordModal;
