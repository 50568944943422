import React, { useState } from "react";
import { googleLogout } from "@react-oauth/google";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../../reducers/user";
import API, { APIComponent, Module } from "../../api/withApi";
import ConfirmModal from "../../partials/ConfirmModal/ConfirmModal";
import EditPropertyModal from "./components/EditPropertyModal";
import EditNameModal from "./components/EditNameModal";
import EditTimezoneModal from "./components/EditTimezoneModal";
import SelectImageModal from "../../partials/SelectImageModal/SelectImageModal";
import { Switch } from "~/src/primitives/switch";

import { RootState } from "../../store";
import capitalize from "lodash/capitalize";
import CropModal from "../../partials/CropModal/CropModal";
import { B64 } from "../../util/loaders";
import { blobToB64 } from "../../util/conversion";
import { username, withPfp } from "~/src/util/reusables";
import { Check, Lock, UserMinus, UserX2 } from "lucide-react";

import "./account.style.css";
import { Button } from "~/src/primitives/button";
import { setError, setSuccess } from "~/src/reducers/toolkit";
import ResetPasswordModal from "./components/ResetPasswordModal";
import EditBusinessNameModal from "./components/EditBusinessNameModal";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../primitives/select";
import Tinybadge from "../../primitives/tinybadge";
import { useNavigate } from "react-router-dom";
import { url } from "../../routes";

type AccountProps = APIComponent;

export const handleLogout = () => {
  googleLogout();
  window.top.location.href = "/api/auth/account/logout";
};

const Account = ({
  deleteUser,
  updateUser,
  setUserMetadata,
  allowMarketingEmails,
  allowApprovalWorkflowEmails,
  resetPassword,
}: AccountProps) => {
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const [editProperty, setEditProperty] = useState<string>();
  const [editName, setEditName] = useState<boolean>(false);
  const [editBusinessName, setEditBusinessName] = useState<boolean>(false);
  const [editPfp, setEditPfp] = useState<boolean>(false);
  const [cropPfp, setCropPfp] = useState<B64>();
  const [editTimezone, setEditTimezone] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [confirmLeaveOrg, setConfirmLeaveOrg] = useState<boolean>(false);
  // const [logo, setLogo] = useState<string>("");

  const handleDeleteUser = async () => {
    const deleteResponse = await deleteUser();
    const status = deleteResponse.status;
    if (status === 204) handleLogout();
  };

  const handleLeaveOrganization = async () =>
    API.leaveOrganization()
      .then(() => API.getSession("?update=true"))
      .then(({ user }) => dispatch(setUser(user)));

  const handleUpdateUser = async (update: any) => {
    const updatedUser = await updateUser(update).then((data) => data.json());
    dispatch(setUser(updatedUser));
  };

  const handleSetMetadata = async (update: any) => {
    const updatedUser = await setUserMetadata(update).then((data) =>
      data.json(),
    );
    dispatch(setUser(updatedUser));
  };

  const [showResetPassword, setShowResetPassword] = useState<boolean>(false);

  const handleResetPassword = (p: string) => {
    resetPassword(p).then((data) => {
      if (data.status) {
        dispatch(setSuccess("Password Reset!"));
        setShowResetPassword(false);
      } else {
        dispatch(setError("An error occured while resetting password"));
      }
    });
  };

  return (
    <div className="relative flex h-full min-h-full w-full flex-col overflow-hidden">
      <div className="flex flex-col overflow-auto pb-8 lg:flex-row lg:gap-8">
        <div className="flex flex-1 flex-shrink-0 flex-col px-4 lg:px-8">
          <div className="section">
            <h5 className="section-label">Profile Photo</h5>
            <div className="value">
              <img
                crossOrigin="anonymous"
                src={withPfp(user?.pfp, username(user))}
                alt=""
                className="profile-photo"
              />
              <Button
                data-testid="pfp-button"
                className="h-7 rounded-full text-2xs font-semibold"
                variant="secondary"
                size="sm"
                onClick={() => setEditPfp(true)}
              >
                Change Picture
              </Button>
            </div>
          </div>

          <div className="section">
            <h5 className="section-label">Name</h5>
            <div className="value" data-testid="display-name">
              <span>
                {user.firstname ? (
                  `${user.firstname} ${user.lastname ?? ""}`
                ) : (
                  <i>Unset</i>
                )}
              </span>
              <Button
                className="h-7 rounded-full text-2xs font-semibold"
                variant="secondary"
                size="sm"
                data-testid="name-button"
                onClick={() => setEditName(true)}
              >
                Change Name
              </Button>
            </div>
          </div>

          <div className="section">
            <h5 className="section-label">Business Name</h5>
            <div className="value" data-testid="business-name">
              <span>
                {user?.metadata?.businessName ? (
                  user.metadata.businessName
                ) : (
                  <i>Unset</i>
                )}
              </span>
              <Button
                className="h-7 rounded-full text-2xs font-semibold"
                variant="secondary"
                size="sm"
                data-testid="business-name-button"
                onClick={() => setEditBusinessName(true)}
              >
                Change Business Name
              </Button>
            </div>
          </div>

          <div className="section">
            <h5 className="section-label">Industry</h5>
            <div className="value">
              <span>
                {user.metadata?.industry ? (
                  `${user.metadata.industry as string}`
                ) : (
                  <i>Unset</i>
                )}
              </span>
              <Button
                className="h-7 rounded-full text-2xs font-semibold"
                variant="secondary"
                size="sm"
                data-testid="industry-button"
                onClick={() => setEditProperty("industry")}
              >
                Change Industry
              </Button>
            </div>
          </div>

          <div className="section">
            <h5 className="section-label">Time Zone</h5>
            <div className="value">
              <span>
                {user.metadata?.timezone || (
                  <i>{Intl.DateTimeFormat().resolvedOptions().timeZone}</i>
                )}
              </span>
              <Button
                className="h-7 rounded-full text-2xs font-semibold"
                variant="secondary"
                size="sm"
                data-testid="timezone-button"
                onClick={() => setEditTimezone(true)}
              >
                Change Time Zone
              </Button>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-shrink-0 flex-col px-4 lg:px-8">
          {/* Email */}
          <div className="section">
            <h5 className="section-label">Email Address</h5>
            <div className="value">
              <span>{user.email || <i>Unset</i>}</span>
            </div>
          </div>
          <div className="section">
            <h5 className="section-label">Email Notifications</h5>
            <div className="value" data-testid="display-name">
              <span>Allow Marketing Emails</span>

              <Switch
                defaultChecked={user.metadata?.notify || false}
                onCheckedChange={async (checked) => {
                  const updatedUser = await allowMarketingEmails(checked).then(
                    (data) => data.json(),
                  );
                  dispatch(setUser(updatedUser));
                }}
              />
            </div>
            <div className="value" data-testid="display-name">
              <span>Allow Approval Workflow Emails</span>

              <Switch
                defaultChecked={
                  !user.metadata?.silenceApprovalWorkflows ?? true
                }
                onCheckedChange={async (checked) => {
                  const updatedUser = await allowApprovalWorkflowEmails(
                    checked,
                  );
                  dispatch(setUser(updatedUser));
                }}
              />
            </div>
          </div>

          <div className="section">
            <div className="flex items-center justify-between">
              <h5 className="section-label">Reset Password</h5>
              <Button
                className="h-7 rounded-full text-2xs font-semibold"
                variant="secondary"
                size="sm"
                data-testid="password-button"
                onClick={() => setShowResetPassword(true)}
              >
                <Lock className="mr-2 h-3 w-3" />
                <span>Reset Your Password</span>
              </Button>
            </div>
          </div>

          {user.manifest.paid && (
            <div className="section">
              <div className="flex items-center justify-between">
                <h5 className="section-label flex items-center gap-4">
                  Select Module{" "}
                  <Tinybadge
                    text="EARLY ACCESS"
                    className="bg-green-50 text-green-700 ring-green-600/20"
                  />
                </h5>
                <Select
                  value={user.module ?? ""}
                  onValueChange={(newModule) => {
                    API.updateUserModule(newModule as Module)
                      .then((updatedUser) => {
                        dispatch(setUser(updatedUser));
                        // dispatch(setSuccess("Module updated successfully"));
                        navigate(
                          newModule === Module.Paid
                            ? url(user, "/paid", undefined, true)
                            : url(user, "/", undefined, true),
                        );
                      })
                      .catch(() => {
                        dispatch(setError("Failed to update module"));
                      });
                  }}
                >
                  <SelectTrigger className="w-[180px]">
                    <SelectValue placeholder="Select a module" />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.entries(Module).map(([key, value]) => (
                      <SelectItem value={value} key={value}>
                        {key}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>
          )}

          {user.organization && (
            <div className="section">
              <div className="flex items-center justify-between">
                <h5 className="section-label">Organization Status</h5>
                <div>
                  {user.manifest?.organization_owner ? (
                    <Button disabled size="sm" variant="outline">
                      <Check className="mr-2 h-4 w-4" />{" "}
                      {`"${user?.organization.name ?? "My Organization"}"`}{" "}
                      {"(Owner)"}
                    </Button>
                  ) : (
                    <Button
                      variant="destructive"
                      size="sm"
                      onClick={() => {
                        setConfirmLeaveOrg(true);
                      }}
                    >
                      <UserMinus className="mr-2 h-4 w-4" /> Leave{" "}
                      {`"${user?.organization.name ?? "My Organization"}"`}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          )}

          {user?.manifest?.sign_out && (
            <div className="section">
              <div className="flex justify-end gap-3">
                <Button
                  variant="outline"
                  onClick={() => handleLogout()}
                  data-testid="signout-button"
                >
                  Sign Out
                </Button>{" "}
                <Button
                  variant="destructive"
                  data-testid="delete-user-button"
                  onClick={() => setConfirmDelete(true)}
                >
                  Disable Account
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>

      <EditPropertyModal
        show={editProperty !== undefined}
        name={capitalize(editProperty)}
        previous={
          (user as any)[editProperty] || (user.metadata as any)[editProperty]
        }
        onChange={(e) => {
          if (Object.keys(user).includes(editProperty)) {
            handleUpdateUser({
              [editProperty]: e,
            });
          } else {
            handleSetMetadata({
              [editProperty]: e,
            });
          }
        }}
        onHide={() => setEditProperty(undefined)}
      />
      <ResetPasswordModal
        show={showResetPassword}
        onHide={() => setShowResetPassword(false)}
        onReset={(p: string) => handleResetPassword(p)}
      />
      <EditNameModal
        show={editName}
        previous={{ first: user.firstname, last: user.lastname }}
        onChange={(e) => {
          handleUpdateUser(e);
        }}
        onHide={() => setEditName(false)}
      />
      <EditBusinessNameModal
        show={editBusinessName}
        previous={user?.metadata?.businessName}
        onChange={(e) => {
          handleUpdateUser({ metadata: { businessName: e } });
        }}
        onHide={() => setEditBusinessName(false)}
      />
      <EditTimezoneModal
        show={editTimezone}
        previous={
          user.metadata?.timezone ??
          Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        onChange={(timezone) => {
          handleSetMetadata({ timezone });
        }}
        onHide={() => setEditTimezone(false)}
      />
      <SelectImageModal
        show={editPfp}
        onChange={(b64) => {
          setEditPfp(false);
          setCropPfp(b64);
        }}
        onHide={() => {
          setEditPfp(false);
        }}
      />
      <CropModal
        show={cropPfp !== undefined}
        load={async () => cropPfp}
        onCrop={async (blob) => {
          const b64 = await blobToB64(blob);
          handleUpdateUser({
            pfp: b64,
          });
        }}
        onHide={() => {
          setCropPfp(undefined);
        }}
      />
      <ConfirmModal
        show={confirmDelete}
        onHide={() => setConfirmDelete(false)}
        header="Are you sure?"
        alertIcon={<UserX2 className="block h-6 w-6" />}
        alertTitle="Disable Account"
        alertDescription="This will deactivate your account. To reactivate you will have to re-verify your email address. If you are sure you want to do this, enter your email address below."
        action="Disable My Account"
        confirmationKey={user.email}
        onConfirm={handleDeleteUser}
      />
      <ConfirmModal
        show={confirmLeaveOrg}
        onHide={() => setConfirmLeaveOrg(false)}
        header="Are you sure?"
        alertIcon={<UserMinus className="block h-6 w-6" />}
        alertTitle="Leave Organization"
        alertDescription="You will have to be invited back into this organization. Are you sure you want to leave?"
        action="Leave"
        confirmationKey={user.email}
        onConfirm={() => {
          handleLeaveOrganization();
          setConfirmLeaveOrg(false);
        }}
      />
    </div>
  );
};

export default Account;
