import "@pqina/pintura/pintura.css";
import "@pqina/pintura-video/pinturavideo.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import "./prosemirror.css";
import "./style.css";

import React, { Suspense, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import {
  createBrowserRouter,
  RouterProvider,
  useRouteError,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ErrorBoundary } from "react-error-boundary";
import API from "./api/withApi";

import { persistor, store } from "./store";
import { loadFonts } from "./lib/Editor/util/fonts";
import { withManifest } from "./partials/CheckManifest/CheckManifest";

import AppLayout from "./layouts/AppLayout";
import HubLayout from "./layouts/HubLayout";
import AnalyticsLayout from "./layouts/AnalyticsLayout";
import PaidLayout from "./layouts/PaidLayout";

import Load from "./partials/Load/Load";
import Fatal from "./views/Error/Fatal";

// Eagerly loaded auth components
import Login from "./views/Auth/Login";
import Signup from "./views/Auth/Signup";
import ForgotPassword from "./views/Auth/ForgotPassword";
import VerifyEmail from "./views/Auth/VerifyEmail";
import SetPassword from "./views/Auth/SetPassword";

// Paid
const PaidOverview = React.lazy(() => import("./views/Paid/Overview"));
const PaidCampaignManage = React.lazy(
  () => import("./views/Paid/Campaigns/Manage"),
);
const PaidCampaignList = React.lazy(
  () => import("./views/Paid/Campaigns/List"),
);
const PaidAnalytics = React.lazy(() => import("./views/Paid/Analytics"));
const PaidAdsetManage = React.lazy(() => import("./views/Paid/Adsets/Manage"));
const PaidAdManage = React.lazy(() => import("./views/Paid/Ads/Manage"));
const PaidAdCreativesList = React.lazy(
  () => import("./views/Paid/Adcreatives/List"),
);
const PaidAdCreativesManage = React.lazy(
  () => import("./views/Paid/Adcreatives/Manage"),
);
const PaidAdFormsList = React.lazy(() => import("./views/Paid/Forms/List"));
const PaidAdFormsManage = React.lazy(() => import("./views/Paid/Forms/Manage"));
const ListPaidTargeting = React.lazy(
  () => import("./views/Paid/Targeting/List"),
);
const ListPaidAudiences = React.lazy(
  () => import("./views/Paid/Audiences/List"),
);
const ListPaidAdLabels = React.lazy(() => import("./views/Paid/Adlabels/List"));

// Lazy loaded components
const OrganicDashboard = React.lazy(
  () => import("./views/Dashboard/OrganicDashboard"),
);
const CalendarView = React.lazy(() => import("./views/Calendar"));
const Edit = React.lazy(() => import("./views/Edit/Edit"));
const Campaigns = React.lazy(() => import("./views/Campaigns"));
const PostDiscussion = React.lazy(
  () => import("./views/PostDiscussion/PostDiscussion"),
);
const Organization = React.lazy(
  () => import("./views/Organization/Organization"),
);
const GuestLayout = React.lazy(() => import("./views/Guest/GuestLayout"));
const GuestCalendar = React.lazy(
  () => import("./views/Guest/components/Calendar"),
);
const GuestPosts = React.lazy(() => import("./views/Guest/components/Posts"));
const GuestMedia = React.lazy(() => import("./views/Guest/components/Media"));
const GuestUpload = React.lazy(() => import("./views/Guest/components/Upload"));
const GuestDashboard = React.lazy(
  () => import("./views/Guest/components/Dashboard"),
);
const UnifiedInbox = React.lazy(
  () => import("./views/CommunityManagement/UnifiedInbox"),
);
const Admin = React.lazy(() => import("./views/Admin/Admin"));
const Profile = React.lazy(() => import("./views/Account/Profile"));
const Help = React.lazy(() => import("./views/Account/Help"));
const Conversion = React.lazy(() => import("./views/Conversion/Conversion"));
const Guides = React.lazy(() => import("./views/Guides"));
const SocialOnboarding = React.lazy(() => import("./views/SocialOnboarding"));
const Library = React.lazy(() => import("./views/Library/Library"));
const Drafts = React.lazy(() => import("./views/Drafts/Drafts"));
const ChainLayout = React.lazy(() => import("./views/Chain/ChainLayout"));
const CampaignDetail = React.lazy(
  () => import("./views/Campaigns/CampaignDetail"),
);
const PostDetail = React.lazy(() => import("./views/Post/PostDetail"));
const DraftDetail = React.lazy(() => import("./views/Post/DraftDetail"));
const Posts = React.lazy(() => import("./views/CommunityManagement/Posts"));
const PostManagement = React.lazy(
  () => import("./views/CommunityManagement/PostManagement"),
);
const AccessDenied = React.lazy(
  () => import("./views/CommunityManagement/AccessDenied"),
);
const CampaignSpawner = React.lazy(
  () => import("./views/Campaigns/CampaignSpawner"),
);
const CommentManagement = React.lazy(
  () => import("./views/CommunityManagement/CommentManagement"),
);
const Billing = React.lazy(() => import("./views/Subscription/Billing"));
const WorkspaceBrand = React.lazy(() => import("./views/Brand"));
const Socials = React.lazy(() => import("./views/Socials/Socials"));
const IntegrationsView = React.lazy(() => import("./views/Integrations"));
const Error404 = React.lazy(() => import("./views/Error/404"));

// Analytics components
const Overview = React.lazy(() => import("./views/Analytics/Overview"));
const Audience = React.lazy(() => import("./views/Analytics/Audience"));
const Activity = React.lazy(() => import("./views/Analytics/Activity"));
const Impressions = React.lazy(() => import("./views/Analytics/Impressions"));
const Engagement = React.lazy(() => import("./views/Analytics/Engagement"));
const ByLocation = React.lazy(() => import("./views/Analytics/ByLocation"));
const Content = React.lazy(() => import("./views/Analytics/Content"));

// Hub components
const HubMedia = React.lazy(() => import("./views/Hubs/components/Media"));
const Concepts = React.lazy(() => import("./views/Concepts"));
const Templates = React.lazy(() => import("./views/Templates"));
const Brand = React.lazy(() => import("./views/Brand"));
const BroadcastEditor = React.lazy(
  () => import("./views/Hubs/components/Locations/BroadcastEditor"),
);
const ConceptCalendar = React.lazy(
  () => import("./views/Hubs/components/ConceptCalendar"),
);
const LocationsTable = React.lazy(
  () => import("./views/Hubs/components/Locations/LocationsTable"),
);
const HubSettings = React.lazy(
  () => import("./views/Hubs/components/HubSettings"),
);
const Leaderboard = React.lazy(
  () => import("./views/Hubs/components/Leaderboard"),
);

declare global {
  interface Window {
    env: any;
  }
}
window.env = window.env || {};
export const queryClient = new QueryClient();

function RouterErrorBoundary() {
  const error = useRouteError();
  console.dir(error, { depth: null });
  return <Fatal error={error} />;
}

const paidRoutes = {
  path: "paid",
  element: <PaidLayout />,
  children: [
    { index: true, element: <PaidOverview /> },
    {
      path: "campaigns",
      children: [
        { index: true, element: <PaidCampaignList /> },
        {
          path: "create",
          element: <PaidCampaignManage key="create" />,
        },
        {
          path: ":campaignId",
          children: [
            { index: true, element: <PaidCampaignManage /> },
            {
              path: "adsets",
              children: [
                {
                  path: "create",
                  element: <PaidAdsetManage key="create" />,
                },
                {
                  path: ":adsetId",
                  children: [
                    { index: true, element: <PaidAdsetManage /> },
                    {
                      path: "ads",
                      children: [
                        {
                          path: "create",
                          element: <PaidAdManage key="create" />,
                        },
                        { path: ":adId", element: <PaidAdManage /> },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "adcreatives",
      children: [
        { index: true, element: <PaidAdCreativesList /> },
        {
          path: "create",
          element: <PaidAdCreativesManage key="create" />,
        },
        { path: ":adCreativeId", element: <PaidAdCreativesManage /> },
      ],
    },
    {
      path: "forms",
      children: [
        { index: true, element: <PaidAdFormsList /> },
        {
          path: "create",
          element: <PaidAdFormsManage key="create" />,
        },
        { path: ":formId", element: <PaidAdFormsManage /> },
      ],
    },
    {
      path: "targeting",
      children: [
        {
          index: true,
          element: <ListPaidTargeting className="overflow-y-auto pt-16" />,
        },
      ],
    },
    {
      path: "adlabels",
      children: [{ index: true, element: <ListPaidAdLabels /> }],
    },
    {
      path: "audiences",
      children: [{ index: true, element: <ListPaidAudiences /> }],
    },
    { path: "analytics", element: <PaidAnalytics /> },
    { path: "socials", element: <Socials /> },
    { path: "help", element: <Help /> },
    { path: "profile", element: <Profile {...API} /> },
  ],
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    errorElement: <RouterErrorBoundary />,
    children: [
      { index: true, element: <OrganicDashboard /> },
      {
        path: "hub/:hubId",
        element: <HubLayout />,
        children: [
          { index: true, element: <OrganicDashboard /> },
          { path: "media", element: <HubMedia /> },
          { path: "concepts", element: <Concepts /> },
          { path: "templates", element: <Templates canManageItems /> },
          { path: "brand", element: <Brand /> },
          { path: "drafts/:portfolioId", element: <BroadcastEditor /> },
          { path: "design", element: <Edit /> },
          { path: "design/template/:templateId", element: <Edit /> },
          { path: "calendar", element: <ConceptCalendar /> },
          {
            path: "drafts",
            element: (
              <Drafts
                onEdit={(pfi, navigate, hubId) =>
                  navigate(`/hub/${hubId}/drafts/${pfi._id}`)
                }
              />
            ),
          },
          {
            path: "analytics",
            element: <AnalyticsLayout />,
            children: [
              { index: true, element: <Overview /> },
              { path: "audience", element: <Audience /> },
              { path: "activity", element: <Activity /> },
              { path: "impressions", element: <Impressions /> },
              { path: "engagement", element: <Engagement /> },
              { path: "locations", element: <ByLocation /> },
              { path: "content", element: <Content /> },
              { path: "leaderboard", element: <Leaderboard canRefresh /> },
            ],
          },
          { path: "locations", element: <LocationsTable /> },
          { path: "settings", element: <HubSettings /> },
          { path: "socials", element: <Socials /> },
          paidRoutes,
        ],
      },
      { path: "socials", element: <Socials /> },
      { path: "integrations", element: <IntegrationsView /> },
      { path: "calendar", element: withManifest(<CalendarView />, "calendar") },
      { path: "media", element: withManifest(<Library />, "view_media") },
      { path: "concepts", element: <Concepts /> },
      {
        path: "drafts",
        element: withManifest(
          <Drafts onEdit={(pfi, navigate) => navigate(`/drafts/${pfi._id}`)} />,
          "view_media",
        ),
      },
      {
        path: "posts",
        element: withManifest(<Posts />, "view_media", <AccessDenied />),
      },
      {
        path: "post/:postId",
        element: withManifest(<PostManagement />, "view_media"),
      },
      { path: "engage", element: <CommentManagement /> },
      { path: "design", element: <Edit /> },
      { path: "design/template/:templateId", element: <Edit /> },
      { path: "templates", element: <Templates canManageItems /> },
      { path: "campaigns", element: withManifest(<Campaigns />, "campaigns") },
      {
        path: "campaigns/spawn",
        element: withManifest(<CampaignSpawner />, "campaigns"),
      },
      {
        path: "campaigns/:campaignId",
        element: withManifest(<CampaignDetail />, "campaigns"),
      },
      {
        path: "analytics",
        element: <AnalyticsLayout />,
        children: [
          { index: true, element: <Overview /> },
          { path: "audience", element: <Audience /> },
          { path: "activity", element: <Activity /> },
          { path: "impressions", element: <Impressions /> },
          { path: "engagement", element: <Engagement /> },
          { path: "content", element: <Content /> },
          { path: "leaderboard", element: <Leaderboard /> },
        ],
      },
      { path: "profile", element: <Profile {...API} /> },
      { path: "help", element: <Help /> },
      { path: "billing", element: withManifest(<Billing />, "subscription") },
      {
        path: "organization",
        element: withManifest(<Organization />, "view_organization"),
      },
      { path: "post/discussion/:larvalid", element: <PostDiscussion /> },
      {
        path: "drafts/:portfolioId",
        element: withManifest(<DraftDetail />, "edit"),
      },
      {
        path: "post/:postId/edit",
        element: withManifest(<PostDetail />, "edit"),
      },
      { path: "brain", element: <ChainLayout /> },
      {
        path: "inbox",
        element: withManifest(<UnifiedInbox />, "inbox", <AccessDenied />),
      },
      { path: "brand", element: <WorkspaceBrand /> },
      paidRoutes,
      // GRAVE YARD ☠️ ------------------------------
      /**
       * @deprecated - 11/08/2024 - No longer relevant, but a fun Easter Egg 🥚
       */
      { path: "guides", element: <Guides /> },
      // ----------------------------------------------
      { path: "*", element: <Error404 /> },
    ],
  },
  { path: "/login", element: <Login {...API} /> },
  { path: "/signup", element: <Signup {...API} /> },
  { path: "/welcome", element: <SocialOnboarding /> },
  { path: "/forgot-password", element: <ForgotPassword {...API} /> },
  { path: "/verify-email", element: <VerifyEmail /> },
  { path: "/set-password", element: <SetPassword /> },
  {
    path: "/guest",
    element: <GuestLayout />,
    children: [
      { index: true, element: <GuestDashboard /> },
      { path: "calendar", element: <GuestCalendar /> },
      { path: "posts", element: <GuestPosts /> },
      { path: "media", element: <GuestMedia /> },
      { path: "upload", element: <GuestUpload /> },
      {
        path: "analytics",
        element: <AnalyticsLayout />,
        children: [
          { index: true, element: <Overview /> },
          { path: "audience", element: <Audience /> },
          { path: "activity", element: <Activity /> },
          { path: "impressions", element: <Impressions /> },
          { path: "engagement", element: <Engagement /> },
        ],
      },
      { path: "posts/:larvalid/discussion", element: <PostDiscussion /> },
      paidRoutes,
      { path: "*", element: <Error404 /> },
    ],
  },
  { path: "/admin", element: withManifest(<Admin />, "admin") },
  { path: "/conversion", element: <Conversion /> },
  { path: "*", element: <Error404 /> },
]);

const App = () => {
  useEffect(() => {
    loadFonts();
  }, []);

  return (
    <Provider store={store}>
      <ErrorBoundary fallbackRender={({ error }) => <Fatal error={error} />}>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <Suspense fallback={<Load />}>
              <RouterProvider router={router} fallbackElement={<Load />} />
            </Suspense>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </PersistGate>
      </ErrorBoundary>
    </Provider>
  );
};

createRoot(document.getElementById("root")).render(<App />);

export default App;
