// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-image .select-preview {
  width: 100px;
  border: 2px white solid;
  border-radius: 5px;
  box-shadow: 0px 2px 4px #efefef;
}

.select-image .select-image-prompt {
  cursor: pointer;
  color: #666;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  padding: 5px;
  box-shadow: 0px 2px 4px transparent;
  transition: 150ms all ease-in;
}

.select-image .select-image-prompt:hover {
  color: #444;
  box-shadow: 0px 2px 4px #efefef;
}

.select-image .file-upload {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./ui/src/partials/SelectImageModal/select-image-modal.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,uBAAuB;EACvB,kBAAkB;EAClB,+BAA+B;AACjC;;AAEA;EACE,eAAe;EACf,WAAW;EACX,yBAAiB;KAAjB,sBAAiB;UAAjB,iBAAiB;EACjB,YAAY;EACZ,mCAAmC;EACnC,6BAA6B;AAC/B;;AAEA;EACE,WAAW;EACX,+BAA+B;AACjC;;AAEA;EACE,aAAa;AACf","sourcesContent":[".select-image .select-preview {\n  width: 100px;\n  border: 2px white solid;\n  border-radius: 5px;\n  box-shadow: 0px 2px 4px #efefef;\n}\n\n.select-image .select-image-prompt {\n  cursor: pointer;\n  color: #666;\n  user-select: none;\n  padding: 5px;\n  box-shadow: 0px 2px 4px transparent;\n  transition: 150ms all ease-in;\n}\n\n.select-image .select-image-prompt:hover {\n  color: #444;\n  box-shadow: 0px 2px 4px #efefef;\n}\n\n.select-image .file-upload {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
