import { User } from "lucide-react";
import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "~/src/primitives/dialog";

interface EditNameModalProps {
  show: boolean;
  previous: {
    first?: string;
    last?: string;
  };
  onChange: (updated: { firstname?: string; lastname?: string }) => void;
  onHide: () => void;
}

const EditNameModal = ({
  show,
  previous,
  onChange,
  onHide,
}: EditNameModalProps) => {
  const [updatedFirst, setUpdatedFirst] = useState<string>();
  const [updatedLast, setUpdatedLast] = useState<string>();

  return (
    <Dialog open={show} onOpenChange={(open) => !open && onHide()}>
      <DialogContent>
        <DialogHeader className="flex flex-row items-center justify-center">
          <User size={20} className="mr-3" />
          <div className="m-0">Change Name</div>
        </DialogHeader>
        <div>
          <input
            type="text"
            className="fl-input"
            data-testid="firstname-input"
            placeholder={previous.first ?? "First Name"}
            onChange={(e) => setUpdatedFirst(e.target.value)}
          />
          <br />
          <input
            type="text"
            className="fl-input"
            data-testid="lastname-input"
            placeholder={previous.last ?? "Last Name"}
            onChange={(e) => setUpdatedLast(e.target.value)}
          />
        </div>

        <DialogFooter>
          <button
            className="fl-button pill small"
            data-testid="property-button"
            onClick={() => {
              onChange({
                firstname: updatedFirst || previous.first,
                lastname: updatedLast || previous.last,
              });
              onHide();
            }}
          >
            Save
          </button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default EditNameModal;
