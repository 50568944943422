import axios from "axios";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Button } from "../../primitives/button";
import { RootState } from "../../store";
import { handleLogout } from "../Account/Profile";

type FatalProps = {
  error: any;
};

export default function Fatal({ error }: FatalProps) {
  const location = useLocation?.();
  const user = useSelector((state: RootState) => state.user);

  useEffect(() => {
    const reportError = async () => {
      try {
        await axios.post("/api/admin/report-error", {
          title: "[Fatal Error] - Client is stuck...",
          location,
          error,
          user: user ? user._id : null,
        });
      } catch (err) {
        console.error("Error reporting to backend:", err);
      }
    };

    if (!window.location.href.includes("localhost")) reportError();
  }, [user, error]);

  return (
    <div className="grid min-h-full place-items-center bg-background px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-red-500">FATAL</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-primary sm:text-5xl">
          An error has occurred
        </h1>
        <p className="mt-6 text-base leading-7 text-secondary-foreground">
          Our team has been notified. Please reach out if the issue persists.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Button onClick={() => handleLogout()}>Sign Out</Button>

          <a
            href="mailto:hello@flamel.ai"
            className="text-sm font-semibold text-primary hover:underline"
          >
            Contact support <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </div>
    </div>
  );
}
