import { useQuery } from "@tanstack/react-query";
import { VariantProps } from "class-variance-authority";
import {
  CircleHelp,
  Home,
  LayoutGrid,
  Folder,
  UsersRound,
  SquareUserRound,
  ChevronRight,
  Loader2,
  Save,
  FileImage,
  FileStack,
  NotebookPen,
  Target,
  BookUser,
  Tags,
  FolderOpen,
} from "lucide-react";
import React, {
  Children,
  isValidElement,
  Fragment,
  ReactNode,
  useMemo,
  useEffect,
} from "react";
import {
  Blocker,
  Link,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  getPaidAdCreative,
  IMetaAdCreative,
  listPaidAdCreatives,
} from "../api/paid/meta/adcreatives.api";
import {
  getPaidAd,
  IMetaAd,
  listPaidAdsForAdset,
  PaidAdStatus,
} from "../api/paid/meta/ads.api";
import {
  getPaidAdset,
  IMetaAdset,
  listPaidAdsetsForCampaign,
  PaidAdsetStatus,
} from "../api/paid/meta/adsets.api";
import {
  getObjective,
  getPaidCampaign,
  PaidCampaignObjective,
  IMetaAdCampaign,
  listPaidCampaigns,
  PaidCampaignStatus,
  checkMetaAccountStatus,
} from "../api/paid/meta/campaigns.api";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../primitives/alert-dialog";
import { Button, buttonVariants } from "../primitives/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "../primitives/command";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../primitives/hover-card";
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from "../primitives/popover";
import { cn, TooltipButton } from "../util/reusables";
import {
  listPaidForms,
  IMetaForm,
  getPaidForm,
} from "../api/paid/meta/forms.api";
import { removeHubPrefix, url } from "../routes";
import { UserAccount } from "../api/auth.api";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { IMetaAudience } from "../api/paid/meta/audiences.api";
import { listPaidAudiences } from "../api/paid/meta/audiences.api";
import { getMetaAdLabels, MetaAdLabel } from "../api/paid/meta/adlabels.api";
import CheckAdAccount from "./components/CheckAdAccount";

export type PaidLayoutContext = {
  campaignsList: IMetaAdCampaign[];
  isLoadingCampaignsList: boolean;
  errorCampaignsList: Error;
  campaign: IMetaAdCampaign;
  isLoadingCampaign?: boolean;
  adsetsList: IMetaAdset[];
  isLoadingAdsetsList: boolean;
  errorAdsetsList: Error;
  adset: IMetaAdset;
  isLoadingAdset?: boolean;
  adsList: IMetaAd[];
  isLoadingAdsList: boolean;
  errorAdsList: Error;
  ad: IMetaAd;
  isLoadingAd?: boolean;
  adCreativesList: IMetaAdCreative[];
  isLoadingAdCreativesList: boolean;
  errorAdCreativesList: Error;
  adCreative: IMetaAdCreative;
  isLoadingAdCreative?: boolean;
  formsList: IMetaForm[];
  isLoadingFormsList: boolean;
  errorFormsList: Error;
  form: IMetaForm;
  isLoadingForm?: boolean;
  audiencesList: IMetaAudience[];
  isLoadingAudiencesList: boolean;
  errorAudiencesList: Error;
  adLabelsList: MetaAdLabel[];
  isLoadingAdLabelsList: boolean;
  errorAdLabelsList: Error;
};

export default function PaidLayout() {
  const user = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { campaignId, adsetId, adId, adCreativeId, formId } = useParams();

  const { data: accountStatus, isLoading: isLoadingAccountStatus } = useQuery({
    queryKey: ["metaAccountStatus", user],
    queryFn: checkMetaAccountStatus,
  });

  // TODO: Remove this once we've rolled out paid to all users
  const checkPaidAccess = (checks: boolean) =>
    user?.manifest?.paid &&
    user?.module === "paid" &&
    !isLoadingAccountStatus &&
    accountStatus?.success &&
    checks;

  /**
   * CAMPAIGNS
   */
  const {
    data: campaignsList = [],
    isLoading: isLoadingCampaignsList,
    error: errorCampaignsList,
  } = useQuery<IMetaAdCampaign[]>({
    queryKey: ["paid", "campaigns"],
    queryFn: () => listPaidCampaigns(),
    enabled: checkPaidAccess(true),
  });
  const { data: campaign, isLoading: isLoadingCampaign } = useQuery({
    queryKey: ["paid", "campaigns", campaignId],
    queryFn: () => getPaidCampaign(campaignId),
    enabled: checkPaidAccess(!!campaignId),
  });
  // ----------------------------------------------------------------

  /**
   * ADSETS
   */
  const {
    data: adsetsList = [],
    isLoading: isLoadingAdsetsList,
    error: errorAdsetsList,
  } = useQuery<IMetaAdset[]>({
    queryKey: [campaignId, "paid", "adsets"],
    queryFn: () => listPaidAdsetsForCampaign(campaign.id),
    enabled: checkPaidAccess(!!campaign?.id),
  });
  const { data: adset, isLoading: isLoadingAdset } = useQuery({
    queryKey: [campaignId, "paid", "adsets", adsetId],
    queryFn: () => getPaidAdset(adsetId),
    enabled: checkPaidAccess(!!adsetId),
  });
  // ----------------------------------------------------------------

  /**
   * ADS
   */
  const {
    data: adsList = [],
    isLoading: isLoadingAdsList,
    error: errorAdsList,
  } = useQuery<IMetaAd[]>({
    queryKey: [adsetId, "paid", "ads"],
    queryFn: () => listPaidAdsForAdset(adset.id),
    enabled: checkPaidAccess(!!adset?.id),
  });
  const { data: ad, isLoading: isLoadingAd } = useQuery({
    queryKey: [adsetId, "paid", "ads", adId],
    queryFn: () => getPaidAd(adId),
    enabled: checkPaidAccess(!!adId),
  });
  // ----------------------------------------------------------------

  /**
   * AD CREATIVES
   */
  const {
    data: adCreativesList = [],
    isLoading: isLoadingAdCreativesList,
    error: errorAdCreativesList,
  } = useQuery<IMetaAdCreative[]>({
    queryKey: ["paid", "adcreatives"],
    queryFn: () => listPaidAdCreatives(),
    enabled: checkPaidAccess(true),
  });

  const { data: adCreative, isLoading: isLoadingAdCreative } = useQuery({
    queryKey: ["paid", "adcreatives", adCreativeId],
    queryFn: () => getPaidAdCreative(adCreativeId),
    enabled: checkPaidAccess(!!adCreativeId),
  });
  // ----------------------------------------------------------------

  /**
   * LEADGEN FORMS
   */
  const {
    data: formsList = [],
    isLoading: isLoadingFormsList,
    error: errorFormsList,
  } = useQuery<IMetaForm[]>({
    queryKey: ["paid", "forms"],
    queryFn: () => listPaidForms(),
    enabled: checkPaidAccess(true),
  });
  const { data: form, isLoading: isLoadingForm } = useQuery({
    queryKey: ["paid", "forms", formId],
    queryFn: () => getPaidForm(formId),
    enabled: checkPaidAccess(!!formId),
  });
  // ----------------------------------------------------------------

  /**
   * AUDIENCES
   */
  const {
    data: audiencesList = [],
    isLoading: isLoadingAudiencesList,
    error: errorAudiencesList,
  } = useQuery<IMetaAudience[]>({
    queryKey: ["paid", "audiences"],
    queryFn: () => listPaidAudiences(),
    enabled: checkPaidAccess(true),
  });
  // ----------------------------------------------------------------

  /**
   * AD LABELS
   */
  const {
    data: adLabelsList = [],
    isLoading: isLoadingAdLabelsList,
    error: errorAdLabelsList,
  } = useQuery<MetaAdLabel[]>({
    queryKey: ["paid", "adlabels"],
    queryFn: () => getMetaAdLabels(),
    enabled: checkPaidAccess(true),
  });
  // ----------------------------------------------------------------

  // Redirect to the last page if there's a flamel-redirect session item, mostly for the `/socials` route
  useEffect(() => {
    const redirect = sessionStorage.getItem("flamel-redirect");
    if (redirect) {
      sessionStorage.removeItem("flamel-redirect");
      navigate(redirect);
    }
  }, []);

  const NavItems = useMemo(() => NAVIGATION_ITEMS(user), [user]);
  const FooterNavItems = useMemo(() => FOOTER_NAVIGATION_ITEMS(user), [user]);

  return (
    <CheckAdAccount user={user}>
      <style>
        {`
        :root {
          --main-background: #093C3E;
          --primary: 182 73.5% 13.3%;
          --primary-foreground: 0 0% 100%;
          --secondary: 182 30% 70%;
          --secondary-foreground: 0 0% 0%;
        }
        .dark {
          --primary: 182 73.5% 13.3%;
          --primary-foreground: 0 0% 100%;
          --secondary: 182 30% 10%;
          --secondary-foreground: 0 0% 100%;
        }
      `}
      </style>
      <div className="relative flex h-full max-h-full min-h-0 w-full overflow-hidden">
        {/* Sidebar */}
        <aside className="flex h-full max-h-full flex-col justify-between overflow-y-auto bg-gradient-to-b from-[#093C3E] to-[#0D1D0F]">
          <nav className="grid gap-1 p-2">
            {NavItems.map((item) => (
              <NavItem key={item.to} item={item} />
            ))}
          </nav>
          <nav className="grid gap-1 p-2">
            {FooterNavItems.map((item) => (
              <NavItem key={item.to} item={item} />
            ))}
          </nav>
        </aside>

        {/* Breadcrumbs */}
        {removeHubPrefix(pathname) !== "/paid" &&
          !FooterNavItems.some((item) => item.isActive(pathname)) && (
            <div className="absolute left-16 top-2 z-10 rounded-md border bg-white p-1 shadow-sm">
              <Breadcrumbs>
                {removeHubPrefix(pathname)?.startsWith("/paid/campaigns") && (
                  <BreadcrumbItem
                    to={url(user, "/paid/campaigns", undefined, true)}
                  >
                    <div className="flex items-center gap-2">
                      {campaignId ? (
                        <FolderOpen className="size-4" />
                      ) : (
                        <Folder className="size-4" />
                      )}
                      Campaigns
                    </div>
                  </BreadcrumbItem>
                )}
                {campaignId ? (
                  <>
                    <BreadcrumbItem
                      to={url(
                        user,
                        `/paid/campaigns/${campaignId}`,
                        undefined,
                        true,
                      )}
                    >
                      <Popover>
                        <PopoverTrigger className="flex items-center gap-2">
                          {campaign?.name || "Loading..."}
                          <Objective
                            objective={
                              campaign?.objective as PaidCampaignObjective
                            }
                            isActive
                          />
                        </PopoverTrigger>
                        <PopoverContent
                          className="w-80 p-0"
                          align="start"
                          alignOffset={-12}
                        >
                          <Command className="bg-muted">
                            <CommandInput
                              className="h-8 rounded-full border bg-white text-xs font-normal text-muted-foreground"
                              placeholder="Search campaigns..."
                            />
                            <CommandList className="bg-white font-normal">
                              <CommandEmpty>No campaigns found.</CommandEmpty>
                              {Object.values(PaidCampaignObjective).map(
                                (objective) => {
                                  const campaignsForObjective =
                                    campaignsList?.filter(
                                      (campaign) =>
                                        campaign.status !==
                                          PaidCampaignStatus.DELETED &&
                                        campaign.objective === objective,
                                    );
                                  if (campaignsForObjective?.length > 0) {
                                    return (
                                      <Fragment key={objective}>
                                        <CommandGroup
                                          heading={
                                            <Objective
                                              objective={objective}
                                              side="right"
                                            />
                                          }
                                        >
                                          {campaignsForObjective.map(
                                            (campaign) => (
                                              <PopoverClose
                                                className="w-full"
                                                key={campaign.id}
                                              >
                                                <CommandItem
                                                  onSelect={() =>
                                                    navigate(
                                                      `/paid/campaigns/${campaign._id}`,
                                                    )
                                                  }
                                                  className="text-sm"
                                                >
                                                  {campaign.name}
                                                </CommandItem>
                                              </PopoverClose>
                                            ),
                                          )}
                                        </CommandGroup>
                                        <CommandSeparator />
                                      </Fragment>
                                    );
                                  }
                                  return null;
                                },
                              )}
                            </CommandList>
                          </Command>
                        </PopoverContent>
                      </Popover>
                    </BreadcrumbItem>
                    {adsetId && (
                      <BreadcrumbItem
                        to={url(
                          user,
                          `/paid/campaigns/${campaignId}/adsets/${adsetId}`,
                          undefined,
                          true,
                        )}
                      >
                        <Popover>
                          <PopoverTrigger className="flex items-center gap-2">
                            <LayoutGrid className="size-4" />
                            {adset?.name || "Loading..."}
                            {adset?.isParent && (
                              <span className="rounded-full border border-primary bg-secondary px-1.5 py-0.5 text-[8px] uppercase leading-none tracking-wider text-primary shadow">
                                Parent
                              </span>
                            )}
                          </PopoverTrigger>
                          <PopoverContent
                            className="relative z-30 w-80 p-0"
                            align="start"
                            alignOffset={-12}
                          >
                            <Command className="bg-muted">
                              <CommandInput
                                className="h-8 rounded-full border bg-white text-xs font-normal text-muted-foreground"
                                placeholder="Search ad sets..."
                              />
                              <CommandList className="bg-white font-normal">
                                <CommandEmpty>No ad sets found.</CommandEmpty>
                                {adsetsList
                                  ?.filter(
                                    (adset) =>
                                      adset.status !== PaidAdsetStatus.DELETED,
                                  )
                                  .map((adset) => (
                                    <PopoverClose
                                      className="w-full"
                                      key={adset.id}
                                    >
                                      <CommandItem
                                        onSelect={() =>
                                          navigate(
                                            `/paid/campaigns/${campaignId}/adsets/${adset._id}`,
                                          )
                                        }
                                        className="text-sm"
                                      >
                                        {adset.name}
                                      </CommandItem>
                                    </PopoverClose>
                                  ))}
                              </CommandList>
                            </Command>
                          </PopoverContent>
                        </Popover>
                      </BreadcrumbItem>
                    )}
                    {pathname.endsWith("/adsets/create") && (
                      <BreadcrumbItem to="#">Create Ad Set</BreadcrumbItem>
                    )}
                    {adId && (
                      <BreadcrumbItem
                        to={url(
                          user,
                          `/paid/campaigns/${campaignId}/adsets/${adsetId}/ads/${adId}`,
                          undefined,
                          true,
                        )}
                      >
                        <Popover>
                          <PopoverTrigger className="flex items-center gap-2">
                            <FileStack className="size-4" />
                            {ad?.name || "Loading..."}
                          </PopoverTrigger>
                          <PopoverContent
                            className="relative z-30 w-80 p-0"
                            align="start"
                            alignOffset={-12}
                          >
                            <Command className="bg-muted">
                              <CommandInput
                                className="h-8 rounded-full border bg-white text-xs font-normal text-muted-foreground"
                                placeholder="Search ads..."
                              />
                              <CommandList className="bg-white font-normal">
                                <CommandEmpty>No ads found.</CommandEmpty>
                                {adsList
                                  ?.filter(
                                    (ad) => ad.status !== PaidAdStatus.DELETED,
                                  )
                                  .map((ad) => (
                                    <PopoverClose
                                      className="w-full"
                                      key={ad.id}
                                    >
                                      <CommandItem
                                        onSelect={() =>
                                          navigate(
                                            `/paid/campaigns/${campaignId}/adsets/${adsetId}/ads/${ad._id}`,
                                          )
                                        }
                                        className="text-sm"
                                      >
                                        {ad.name}
                                      </CommandItem>
                                    </PopoverClose>
                                  ))}
                              </CommandList>
                            </Command>
                          </PopoverContent>
                        </Popover>
                      </BreadcrumbItem>
                    )}
                    {pathname.endsWith("/ads/create") && (
                      <BreadcrumbItem to="#">Create Ad</BreadcrumbItem>
                    )}
                  </>
                ) : (
                  removeHubPrefix(pathname) === "/paid/campaigns/create" && (
                    <BreadcrumbItem to="#">Create Campaign</BreadcrumbItem>
                  )
                )}

                {/* AD CREATIVES */}
                {removeHubPrefix(pathname)?.startsWith("/paid/adcreatives") && (
                  <>
                    <BreadcrumbItem
                      to={url(user, "/paid/adcreatives", undefined, true)}
                    >
                      <div className="flex items-center gap-2">
                        <FileImage className="size-4" />
                        Ad Creatives
                      </div>
                    </BreadcrumbItem>
                    {removeHubPrefix(pathname) ===
                    "/paid/adcreatives/create" ? (
                      <BreadcrumbItem
                        to={url(
                          user,
                          "/paid/adcreatives/create",
                          undefined,
                          true,
                        )}
                      >
                        <div className="flex items-center gap-2">
                          Create Ad Creative
                        </div>
                      </BreadcrumbItem>
                    ) : (
                      adCreativeId && (
                        <BreadcrumbItem
                          to={url(
                            user,
                            `/paid/adcreatives/${adCreativeId}`,
                            undefined,
                            true,
                          )}
                        >
                          <Popover>
                            <PopoverTrigger className="flex items-center gap-2">
                              {adCreative?.name || "Loading..."}
                            </PopoverTrigger>
                            <PopoverContent
                              className="relative z-30 w-80 p-0"
                              align="start"
                              alignOffset={-12}
                            >
                              <Command className="bg-muted">
                                <CommandInput
                                  className="h-8 rounded-full border bg-white text-xs font-normal text-muted-foreground"
                                  placeholder="Search ad creatives..."
                                />
                                <CommandList className="bg-white font-normal">
                                  <CommandEmpty>
                                    No ad creatives found.
                                  </CommandEmpty>
                                  {adCreativesList?.map((adCreative) => (
                                    <PopoverClose
                                      className="w-full"
                                      key={adCreative._id}
                                    >
                                      <CommandItem
                                        onSelect={() =>
                                          navigate(
                                            `/paid/adcreatives/${adCreative._id}`,
                                          )
                                        }
                                        className="text-sm"
                                      >
                                        {adCreative.name}
                                      </CommandItem>
                                    </PopoverClose>
                                  ))}
                                </CommandList>
                              </Command>
                            </PopoverContent>
                          </Popover>
                        </BreadcrumbItem>
                      )
                    )}
                  </>
                )}

                {/* FORMS */}
                {removeHubPrefix(pathname)?.startsWith("/paid/forms") && (
                  <Fragment>
                    <BreadcrumbItem
                      to={url(user, "/paid/forms", undefined, true)}
                    >
                      <div className="flex items-center gap-2">
                        <NotebookPen className="size-4" />
                        Instant Forms
                      </div>
                    </BreadcrumbItem>
                    {removeHubPrefix(pathname) === "/paid/forms/create" ? (
                      <BreadcrumbItem
                        to={url(user, "/paid/forms/create", undefined, true)}
                      >
                        <div className="flex items-center gap-2">
                          Create Instant Form
                        </div>
                      </BreadcrumbItem>
                    ) : (
                      formId && (
                        <BreadcrumbItem
                          to={url(
                            user,
                            `/paid/forms/${formId}`,
                            undefined,
                            true,
                          )}
                        >
                          <Popover>
                            <PopoverTrigger className="flex items-center gap-2">
                              {form?.name || "Loading..."}
                            </PopoverTrigger>
                            <PopoverContent
                              className="relative z-30 w-80 p-0"
                              align="start"
                              alignOffset={-12}
                            >
                              <Command className="bg-muted">
                                <CommandInput
                                  className="h-8 rounded-full border bg-white text-xs font-normal text-muted-foreground"
                                  placeholder="Search forms..."
                                />
                                <CommandList className="bg-white font-normal">
                                  <CommandEmpty>No forms found.</CommandEmpty>
                                  {formsList?.map((form) => (
                                    <PopoverClose
                                      className="w-full"
                                      key={form.id}
                                    >
                                      <CommandItem
                                        onSelect={() =>
                                          navigate(`/paid/forms/${form.id}`)
                                        }
                                        className="text-sm"
                                      >
                                        {form.name}
                                      </CommandItem>
                                    </PopoverClose>
                                  ))}
                                </CommandList>
                              </Command>
                            </PopoverContent>
                          </Popover>
                        </BreadcrumbItem>
                      )
                    )}
                  </Fragment>
                )}

                {/* TARGETING */}
                {removeHubPrefix(pathname).startsWith("/paid/targeting") && (
                  <Fragment>
                    <BreadcrumbItem
                      to={url(user, "/paid/targeting", undefined, true)}
                    >
                      <div className="flex items-center gap-2">
                        <Target className="size-4" />
                        Targeting
                      </div>
                    </BreadcrumbItem>
                  </Fragment>
                )}

                {/* AUDIENCES */}
                {removeHubPrefix(pathname).startsWith("/paid/audiences") && (
                  <Fragment>
                    <BreadcrumbItem
                      to={url(user, "/paid/audiences", undefined, true)}
                    >
                      <div className="flex items-center gap-2">
                        <BookUser className="size-4" />
                        Audiences
                      </div>
                    </BreadcrumbItem>
                  </Fragment>
                )}

                {/* AD LABELS */}
                {removeHubPrefix(pathname).startsWith("/paid/adlabels") && (
                  <Fragment>
                    <BreadcrumbItem
                      to={url(user, "/paid/adlabels", undefined, true)}
                    >
                      <div className="flex items-center gap-2">
                        <Tags className="size-4" />
                        Ad Labels
                      </div>
                    </BreadcrumbItem>
                  </Fragment>
                )}
              </Breadcrumbs>
            </div>
          )}

        {/* Main Component */}
        <div className="flex h-full min-h-full w-full flex-col overflow-hidden rounded-tl-2xl bg-background">
          <Outlet
            context={
              {
                campaignsList,
                isLoadingCampaignsList,
                errorCampaignsList,
                campaign,
                isLoadingCampaign,
                adsetsList,
                isLoadingAdsetsList,
                errorAdsetsList,
                adset,
                isLoadingAdset,
                adsList,
                isLoadingAdsList,
                errorAdsList,
                ad,
                isLoadingAd,
                adCreativesList,
                isLoadingAdCreativesList,
                errorAdCreativesList,
                adCreative,
                isLoadingAdCreative,
                formsList,
                isLoadingFormsList,
                errorFormsList,
                form,
                isLoadingForm,
                audiencesList,
                isLoadingAudiencesList,
                errorAudiencesList,
                adLabelsList,
                isLoadingAdLabelsList,
                errorAdLabelsList,
              } as PaidLayoutContext
            }
          />
        </div>
      </div>
    </CheckAdAccount>
  );
}

type NavItem = {
  icon: React.ComponentType<{ className?: string }>;
  label: string;
  to: string;
  isActive: (pathname: string) => boolean;
  disabled?: boolean;
} & VariantProps<typeof buttonVariants>;

const NAVIGATION_ITEMS = (user: UserAccount): NavItem[] => [
  {
    icon: Home,
    label: "Home",
    to: url(user, "/paid", undefined, true),
    variant: "outlinePaid",
    isActive: (pathname) => removeHubPrefix(pathname) === "/paid",
  },
  {
    icon: Folder,
    label: "Campaigns",
    to: url(user, "/paid/campaigns", undefined, true),
    isActive: (pathname) =>
      removeHubPrefix(pathname).startsWith("/paid/campaigns"),
  },
  {
    icon: FileImage,
    label: "Ad Creatives",
    to: url(user, "/paid/adcreatives", undefined, true),
    isActive: (pathname) =>
      removeHubPrefix(pathname).startsWith("/paid/adcreatives"),
  },
  {
    icon: NotebookPen,
    label: "Instant Forms",
    to: url(user, "/paid/forms", undefined, true),
    isActive: (pathname) => removeHubPrefix(pathname).startsWith("/paid/forms"),
  },
  // {
  //   icon: Target,
  //   label: "Targeting",
  //   to: url(user, "/paid/targeting", undefined, true),
  //   isActive: (pathname) =>
  //     removeHubPrefix(pathname).startsWith("/paid/targeting"),
  // },
  {
    icon: BookUser,
    label: "Audiences",
    to: url(user, "/paid/audiences", undefined, true),
    isActive: (pathname) =>
      removeHubPrefix(pathname).startsWith("/paid/audiences"),
  },
  // {
  //   icon: Tags,
  //   label: "Ad Labels",
  //   to: url(user, "/paid/adlabels", undefined, true),
  //   isActive: (pathname) =>
  //     removeHubPrefix(pathname).startsWith("/paid/adlabels"),
  // },
];

const FOOTER_NAVIGATION_ITEMS = (user: UserAccount): NavItem[] => [
  {
    icon: UsersRound,
    label: "Social Accounts",
    to: url(user, "/paid/socials"),
    isActive: (pathname) => removeHubPrefix(pathname) === "/paid/socials",
  },
  {
    icon: CircleHelp,
    label: "Help",
    to: url(user, "/paid/help"),
    isActive: (pathname) => removeHubPrefix(pathname) === "/paid/help",
  },
  {
    icon: SquareUserRound,
    label: "Profile",
    to: url(user, "/paid/profile"),
    isActive: (pathname) => removeHubPrefix(pathname) === "/paid/profile",
  },
];

function NavItem({ item }: { item: NavItem }) {
  const { pathname } = useLocation();
  const isActive = useMemo(() => item.isActive(pathname), [item, pathname]);

  return (
    <NavLink
      to={item.to}
      className={cn(
        "rounded-lg transition-all hover:bg-muted",
        isActive &&
          "hover:text-[#C5E99F][&>*>*]:border-transparent rounded-full bg-white/10 text-[#C5E99F] hover:hover:bg-[#0D4949]/80 [&>*]:border-transparent",
      )}
    >
      <TooltipButton aria-label={item.label} text={item.label} side="right">
        <Button
          variant={item.variant ?? "ghostDark"}
          disabled={item.disabled}
          size="icon"
          className={cn(
            "transition-all hover:hover:bg-[#0D4949]/80",
            isActive && "rounded-full text-[#C5E99F]",
          )}
        >
          <item.icon
            className={cn(
              "size-5 transition-all",
              isActive ? "text-[#C5E99F]" : "text-muted",
            )}
          />
        </Button>
      </TooltipButton>
    </NavLink>
  );
}
interface BreadcrumbItemProps {
  to: string;
  isActive?: boolean;
  children: ReactNode;
}

function BreadcrumbItem({ to, isActive, children }: BreadcrumbItemProps) {
  const content = (
    <div className="flex items-center gap-2">
      {React.Children.map(children, (child) =>
        React.isValidElement(child) && child.type === Objective
          ? //@ts-ignore
            React.cloneElement(child, { isActive })
          : child,
      )}
    </div>
  );

  return (
    <li className="inline-flex items-center">
      {isActive ? (
        <h1 className="ml-1 flex items-center gap-2 rounded-md bg-primary px-3 py-1 text-sm font-semibold text-primary-foreground">
          {content}
        </h1>
      ) : (
        <Link
          to={to}
          className={cn(
            "ml-1 inline-flex items-center gap-2 rounded-md px-3 py-1 text-sm font-medium",
            "text-muted-foreground transition-colors hover:bg-accent hover:text-accent-foreground",
          )}
        >
          {content}
        </Link>
      )}
    </li>
  );
}
interface BreadcrumbsProps {
  children: ReactNode;
}

function Breadcrumbs({ children }: BreadcrumbsProps) {
  const flattenChildren = (children: React.ReactNode): React.ReactElement[] => {
    return Children.toArray(children).reduce(
      (acc: React.ReactElement[], child) => {
        if (isValidElement(child)) {
          if (child.type === Fragment) {
            return acc.concat(flattenChildren(child.props.children));
          }
          if (child.type === BreadcrumbItem) {
            return acc.concat(child);
          }
        }
        return acc;
      },
      [],
    ) as any;
  };

  const items = flattenChildren(children);

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="inline-flex items-center">
        {items.map((item, index) => (
          <React.Fragment key={index}>
            {index > 0 && (
              <ChevronRight className="h-4 w-4 text-muted-foreground" />
            )}
            {React.cloneElement(item, {
              isActive: index === items.length - 1,
            })}
          </React.Fragment>
        ))}
      </ol>
    </nav>
  );
}

export function Objective({
  objective,
  isActive,
  side,
  className,
}: {
  objective?: PaidCampaignObjective;
  isActive?: boolean;
  side?: "left" | "right" | "top" | "bottom";
  className?: string;
}) {
  const campaignObjective = useMemo(() => getObjective(objective), [objective]);

  if (!campaignObjective) return null;

  return (
    <HoverCard>
      <HoverCardTrigger asChild>
        <span
          className={cn(
            "inline-flex cursor-help items-center rounded-md px-2 py-0.5 text-2xs font-medium",
            campaignObjective.color,
            isActive && "text-primary",
            className,
          )}
        >
          <campaignObjective.icon className="mr-1 size-2.5" />
          {campaignObjective.label}
        </span>
      </HoverCardTrigger>
      <HoverCardContent className="w-80 p-3" side={side}>
        <p className="text-sm font-medium">
          {campaignObjective.shortDescription}
        </p>
      </HoverCardContent>
    </HoverCard>
  );
}

export function UnsavedChangesDialog({
  blocker,
  onSave,
  isPending,
}: {
  blocker: Blocker;
  onSave?: () => void | Promise<void>;
  isPending?: boolean;
}) {
  return (
    <AlertDialog open={blocker.state === "blocked"}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Unsaved Changes</AlertDialogTitle>
          <AlertDialogDescription>
            You have unsaved changes. Are you sure you want to proceed without
            saving?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter className="sm:justify-between">
          {onSave ? (
            <Button
              variant="constructive"
              onClick={() => onSave()}
              disabled={isPending}
            >
              {isPending ? (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              ) : (
                <Save className="mr-2 h-4 w-4" />
              )}
              Save and Proceed
            </Button>
          ) : (
            <div></div>
          )}
          <div className="flex items-center gap-2">
            <AlertDialogCancel onClick={() => blocker.reset()}>
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction onClick={() => blocker.proceed()}>
              Proceed
            </AlertDialogAction>
          </div>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
